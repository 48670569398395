import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RepaymentService {

  constructor(
    private http: HttpClient
  ) { }

  public getPartialRepaymentAmount(contract_number: string, isKaspi: boolean) {
    return this.http.post<any>(`${environment.apiUrl}/api/user/loan/payment/partial/sum/`, {
      contract_number: contract_number,
      kaspi: isKaspi
    });
  }

  public partialRepaymentAmount(contract_number: string, amount: number) {
    return this.http.post<any>(`${environment.apiUrl}/api/user/loan/payment/partial/`, {
      contract_number: contract_number,
      amount: amount
    });
  }

  public getFullRepaymentAmount(contract_number: string, isKaspi: boolean) {
    return this.http.post<any>(`${environment.apiUrl}/api/user/loan/payment/full/sum/`, {
      contract_number: contract_number,
      kaspi: isKaspi
    });
  }

  public fullRepaymentAmount(contract_number: string, amount: number, isKaspi: boolean) {
    return this.http.post<any>(`${environment.apiUrl}/api/user/loan/payment/full/`, {
      contract_number: contract_number,
      amount: amount,
      kaspi: isKaspi
    });
  }
}
