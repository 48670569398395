import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TUserInfo } from "src/app/models/user";
import { UnsubscribeService } from "src/app/services/unsubscribe/unsubscribe.service";
import { UserService } from "src/app/services/user/user.service";
import { MyLocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/services/auth/auth.service";
import { RepaymentService } from "src/app/services/repayment/repayment.service";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { CreditContract } from "src/app/models/credit";

declare let $: any;

@Component({
  selector: "app-profile-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfilePaymentsComponent implements OnInit, OnDestroy {
  @Input() userInfo: TUserInfo;

  public requestErrors: any = {};

  public historyPaymentOld = new Array<HistoryPayment>();
  public historyPayment = new Array<HistoryPayment>();

  public repaymentHistory: [];

  public productType: string = "";
  public loan_credits_contract = new CreditContract();

  public fromDatePeriod: Date;
  public toDatePeriod: Date;
  public userContractInfo: any;

  public repaymentSumm = 0;
  public selectedContract: number = 0;

  public dates: {
    from: Date;
    to: Date;
  } = {
    from: new Date(Date.now() - 24 * 60 * 60 * 1000 * 30),
    to: new Date(Date.now()),
  };

  public repayment = {
    fromDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
    IP: false,
  };

  constructor(
    private unsubscribeService: UnsubscribeService,
    private userService: UserService,
    private localStorageService: MyLocalStorageService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private repaymentService: RepaymentService,
    private numseperator: NumSeparatorPipe
  ) {}

  ngOnInit() {
    var d = new Date(new Date().getTime() + 6 * 3600 * 1000);
  }

  private getPrepaymentHistory(contractInfo) {
    this.userService
      .getRepaymentHistory(
        contractInfo.contract_number,
        contractInfo.date_of_conclusion,
        this.repayment.fromDate,
        this.repayment.toDate,
        this.repayment.IP ? "entity" : "individual"
      )
      .subscribe((_) => {
        try {
          this.repaymentHistory = _;
          this.repaymentSumm =
            _.length > 0
              ? this.repaymentHistory
                  .map((_: any) => _.payment)
                  .reduce((_: any, result: any) => _ + result)
              : 0;
        } catch {}
      });
  }

  private changeDateTimeout: any = 0;

  public changeFromPeriodDate(event: any, force: boolean = false) {
    if (force) event.value = event;
    this.dates.from = event.value;
    this.fromDatePeriod = event.value;
    this.repayment.fromDate = new Date(
      event.value - event.value.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .split("T")[0];
    try {
      this.historyPayment = this.historyPaymentOld.filter((x) => {
        return (
          new Date(x.targetDates) >= this.fromDatePeriod &&
          (this.toDatePeriod
            ? new Date(x.targetDates) <= this.toDatePeriod
            : true)
        );
      });
    } catch (e) {}
    clearTimeout(this.changeDateTimeout);
    this.changeDateTimeout = setTimeout(() => {
      this.getPrepaymentHistory(
        this.userInfo.user_contract_info[this.selectedContract]
      );
    }, 500);
  }

  public changeToPeriodDate(event: any, force: boolean = false) {
    if (force) event.value = event;
    let date = new Date(event.value.getTime() + 24 * 60 * 60 * 1000);
    this.repayment.toDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .split("T")[0];
    // console.log(this.repayment.toDate);
    this.toDatePeriod = date;
    this.historyPayment = this.historyPaymentOld.filter((x) => {
      return (
        new Date(x.targetDates) <= this.toDatePeriod &&
        (this.fromDatePeriod
          ? new Date(x.targetDates) >= this.fromDatePeriod
          : true)
      );
    });
    clearTimeout(this.changeDateTimeout);
    this.changeDateTimeout = setTimeout(() => {
      this.getPrepaymentHistory(
        this.userInfo.user_contract_info[this.selectedContract]
      );
    }, 500);
  }

  ngOnDestroy() {}
}
