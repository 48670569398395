export class CreditContract {
  product: string;
  borrower: number;
  params: string;
  contract_number: string;
  contract_date: string;
  contract_status: string;
  reward: string;
  payments: [];
  remaining_principal: string;
  overdue_amount: string;
  penalty_amount: string;
  signed_at: string;
  closed_at: string;
  repayment_method: string;
  credit_id: number;
  id: number;
}
