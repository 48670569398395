export enum DepositStatus {
  NEW = "NEW",
  IN_PROGRESS = "IN_PROGRESS",
  IN_WORK = "IN_WORK",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCEL = "CANCEL",
  SIGNED = "SIGNED",
}

export interface DepositAddress {
  id: number;
  country: string;
  region?: string;
  city?: string;
  district?: string;
  street?: string;
  building?: string;
  corpus?: string;
  flat?: string;
}

export interface Depositor {
  id: number;
  full_name: string;
  address: DepositAddress;
  document_issue_date: string;
  created: string;
  modified: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  tax_number?: string;
  resident: boolean;
  citizenship?: string;
  document_type?: string;
  document_series?: string;
  document_number?: string;
  document_exp_date?: string;
  document_issue_org?: string;
  education?: string;
  job_place?: string;
  job_title?: string;
  phone?: string;
  validated_at?: string;
  validated?: boolean;
  profile: number;
}

export interface CurrencyPrint {
  id: number;
  created: string;
  modified: string;
  code: string;
  name: string;
  is_active: boolean;
}

export interface Deposit {
  id: number;
  depositor: Depositor;
  currency: CurrencyPrint;
  created: string;
  modified: string;
  uuid: string;
  phone?: string;
  email?: string;
  amount: string;
  period: number;
  interest_rate?: string;
  target_purpose: string;
  confirmed_at?: string;
  confirmed?: boolean;
  verified?: boolean;
  verified_at?: string;
  otp_apply?: string;
  otp_signature?: string;
  signed_at?: string;
  status: DepositStatus;
}
