import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { ContractSign } from "src/app/models/user";
import { UserService } from "src/app/services/user/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/services/auth/auth.service";
import { TimeSnackbarShow } from "src/app/config";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { CreditContract } from "src/app/models/credit";
import { Deposit, DepositStatus } from "src/app/models/deposit";

declare let $: any;

@Component({
  selector: "app-profile-contract",
  templateUrl: "./contract.component.html",
  styleUrls: ["./contract.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfileContractComponent implements OnInit, OnDestroy {
  @Output() changeTab = new EventEmitter<number>();

  public deposit: Deposit = {
    id: 0,
    depositor: undefined,
    currency: undefined,
    created: "",
    modified: "",
    uuid: "",
    amount: "",
    period: 0,
    target_purpose: "",
    status: DepositStatus.NEW,
  };
  public requestErrors: any = {};
  public signing = new ContractSign();
  public loan_credits_contract = new CreditContract();
  public step = 4;
  public url = "";

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  check1 = false;
  check2 = false;

  ngOnInit() {
    this.getLoanCreditsContract();
    this.getDepositList();
  }

  onCheck(state: boolean, check: string) {
    if (check == "check1") {
      this.check1 = state;
    } else {
      this.check2 = state;
    }
  }

  public handleFileECP(files: FileList) {
    this.signing.key = files.item(0);
  }

  public getContractsFile(id) {
    this.userService.getContractsFile(id).subscribe(
      (res: any) => {
        this.url = res;
        $("#modalSuccess").modal("show");
        this.changeTab.emit(1);
        this.step = 4;
      },
      (err) => {
        if (err.status === 401) {
          this.snackBar.open(
            "Для начала пожалуйста, войдите в свой аккаунт.",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
          this.authService.logout();
        }
      }
    );
  }

  public contractSign() {
    this.userService
      .contractSign(
        this.loan_credits_contract.id,
        this.signing.key,
        this.signing.password
      )
      .subscribe(
        (res: any) => {
          this.getContractsFile(this.loan_credits_contract.id);
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  public validateToSign() {
    this.userService.getValidateToSign().subscribe(
      (res: any) => {
        console.log("validateToSign()", res);
      },
      (err) => {
        if (err.status === 401) {
          this.snackBar.open(
            "Для начала пожалуйста, войдите в свой аккаунт.",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
          this.authService.logout();
        }
      }
    );
  }

  public ddcapApply() {
    this.userService.getDdcapApply().subscribe(
      (res: any) => {
        if (res.status === "OK") this.step = 2;
      },
      (err) => {
        this.requestErrors = err.error;
        let errMsg = "";
        for (let key in err.error) {
          errMsg += err.error[key] + "\n";
        }

        this.snackBar.open(errMsg || "Что то пошло не так!", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
      }
    );
  }

  public getLoanCreditsContract() {
    this.userService.getLoanCreditsContract().subscribe(
      (res: any) => {
        if (res.id) {
          this.loan_credits_contract = res;
          this.step = 1;
        } else {
          this.step = 4;
        }
      },
      (err) => {
        this.step = 4;
        this.requestErrors = err.error;
        let errMsg = "";
        for (let key in err.error) {
          errMsg += err.error[key] + "\n";
        }

        this.snackBar.open(errMsg || "Что то пошло не так!", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
      }
    );
  }

  public getPrintForms(credit_id: string | number, form_name: string) {
    this.userService.getPrintForms(credit_id, form_name).subscribe(
      (blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      },
      (err) => {
        if (err.status === 401) {
          this.snackBar.open(
            "Для начала пожалуйста, войдите в свой аккаунт.",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
          this.authService.logout();
        }
      }
    );
  }

  ngOnDestroy() {}

  public getDepositList() {
    this.userService.getDepositList().subscribe(
      (res: Deposit[]) => {
        this.deposit = res.find(
          (item) => item.status === DepositStatus.APPROVED
        );
        if (this.deposit.id) {
          this.step = 5;
        } else {
          this.step = 4;
        }
      },
      (err) => {
        this.step = 4;
        this.requestErrors = err.error;
        let errMsg = "";
        for (let key in err.error) {
          errMsg += err.error[key] + "\n";
        }

        this.snackBar.open(errMsg || "Что то пошло не так!", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
      }
    );
  }

  public getDepositPrintForms(credit_id: string | number, form_name: string) {
    this.userService.getDepositPrintForms(credit_id, form_name).subscribe(
      (blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      },
      (err) => {
        if (err.status === 401) {
          this.snackBar.open(
            "Для начала пожалуйста, войдите в свой аккаунт.",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
          this.authService.logout();
        }
      }
    );
  }

  public sendOTPDeposit() {
    this.userService.sendOTPDeposit().subscribe(
      (res: any) => {
        this.snackBar.open("OTP код отправлен на ваш номер", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
        $("#sendOtpDepositModal").modal("show");
      },
      (err) => {
        if (err.status === 400) {
          this.snackBar.open(err.error.detail, null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      }
    );
  }
}
