import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserService } from "src/app/services/user/user.service";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";

@Component({
  selector: "app-profile-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfileHistoryComponent implements OnInit, OnDestroy {
  public requestErrors: any = {};
  public applicationHistory = [];
  public repaymentHistory: [];

  public fromDatePeriod: Date;
  public toDatePeriod: Date;
  public selectedContract: number = 0;

  public historyPaymentOld = new Array<HistoryPayment>();
  public historyPayment = new Array<HistoryPayment>();

  constructor(private userService: UserService) {}

  check1 = false;
  check2 = false;

  public password_is_generated: boolean = false;

  public repayment = {
    fromDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
    IP: false,
  };

  ngOnInit() {}

  private getLoanRequestHistory() {
    this.userService
      .getUserLoanRequestHistory(this.repayment.fromDate, this.repayment.toDate)
      .subscribe((_: any) => {
        this.applicationHistory = _;
      });
  }

  public repaymentSumm = 0;

  public dates: {
    from: Date;
    to: Date;
  } = {
    from: new Date(Date.now() - 24 * 60 * 60 * 1000 * 30),
    to: new Date(Date.now()),
  };

  private changeDateTimeout: any = 0;

  public changeFromPeriodDate(event: any, force: boolean = false) {
    if (force) event.value = event;
    this.dates.from = event.value;
    this.fromDatePeriod = event.value;
    this.repayment.fromDate = new Date(
      event.value - event.value.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .split("T")[0];
    try {
      this.historyPayment = this.historyPaymentOld.filter((x) => {
        return (
          new Date(x.targetDates) >= this.fromDatePeriod &&
          (this.toDatePeriod
            ? new Date(x.targetDates) <= this.toDatePeriod
            : true)
        );
      });
    } catch (e) {}
    clearTimeout(this.changeDateTimeout);
    this.changeDateTimeout = setTimeout(() => {
      this.getLoanRequestHistory();
    }, 500);
  }

  public changeToPeriodDate(event: any, force: boolean = false) {
    if (force) event.value = event;
    let date = new Date(event.value.getTime() + 24 * 60 * 60 * 1000);
    this.repayment.toDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .split("T")[0];
    this.toDatePeriod = date;
    this.historyPayment = this.historyPaymentOld.filter((x) => {
      return (
        new Date(x.targetDates) <= this.toDatePeriod &&
        (this.fromDatePeriod
          ? new Date(x.targetDates) >= this.fromDatePeriod
          : true)
      );
    });
    clearTimeout(this.changeDateTimeout);
    this.changeDateTimeout = setTimeout(() => {
      this.getLoanRequestHistory();
    }, 500);
  }

  ngOnDestroy() {}
}
