import { TimeSnackbarShow } from "../../config";
import { UserService } from "src/app/services/user/user.service";
import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";

declare let $: any;

@Component({
  selector: "app-modal-otp-deposit",
  templateUrl: "./modal-otp-deposit.component.html",
  styleUrls: ["./modal-otp-deposit.component.scss"],
  providers: [MatSnackBar],
})
export class ModalOTPDepositComponent implements OnInit, OnDestroy {
  @Output() changeTab = new EventEmitter<number>();
  @Input() deposit_id: number;
  private destroyed$ = new Subject();

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  otp_code: string = "";
  disabled: boolean = false;
  disabledBtn: boolean = true;
  replay: number = 3;
  countdown: number = 60;
  text = "";
  check1 = false;
  check2 = false;

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  transformToUpper(value: string): void {
    this.otp_code = value.toUpperCase().substring(0, 6);
  }

  resendOTP() {
    this.disabled = true;

    let timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown = --this.countdown;
      } else {
        clearInterval(timer);
        this.disabled = false;
        this.countdown = 60;
      }
    }, 1000);

    this.userService.sendOTPDeposit().subscribe(
      (res: any) => {
        this.snackBar.open("OTP код отправлен на ваш номер", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
        this.replay -= 1;
        if (this.replay === 0) {
          this.disabled = true;
        }
      },
      (err) => {
        if (err.status === 400) {
          this.snackBar.open(err.error.detail, null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      }
    );
  }

  onScoringChange(state: boolean, check: string) {
    if (check == "check1") {
      this.check1 = state;
    } else {
      this.check2 = state;
    }
  }

  verifyOTP() {
    this.userService.singOTPDeposit(this.otp_code).subscribe(
      (res: any) => {
        if (res.status) {
          this.snackBar.open("Поздравляем Вас с успешным подписанием!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
          $("#sendOtpDepositModal").modal("hide");
          this.changeTab.emit(2);
        } else {
          this.snackBar.open(
            "Введенный код неверный, нажмите на Отправить СМС и введите код повторно",
            null,
            {
              duration: TimeSnackbarShow,
              verticalPosition: "top",
            }
          );
        }
      },
      (err) => {
        this.snackBar.open(err.error.detail || "Ошибка сервера", null, {
          duration: TimeSnackbarShow,
          verticalPosition: "top",
        });
      }
    );
  }
}
