import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TUserInfo, Tranches } from "src/app/models/user";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { UserService } from "src/app/services/user/user.service";
import { Deposit, DepositStatus } from "src/app/models/deposit";

declare let $: any;

@Component({
  selector: "app-profile-loan-list",
  templateUrl: "./loan-list.component.html",
  styleUrls: ["./loan-list.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfileLoanListComponent implements OnInit, OnDestroy {
  @Input() userInfo: TUserInfo;
  public requestErrors: any = {};
  public tranches: Tranches;

  public deposit: Deposit = {
    id: 0,
    depositor: undefined,
    currency: undefined,
    created: "",
    modified: "",
    uuid: "",
    amount: "",
    period: 0,
    target_purpose: "",
    status: DepositStatus.SIGNED,
  };

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.getDepositList();
  }

  public getDepositList() {
    this.userService.getDepositList().subscribe((res: Deposit[]) => {
      this.deposit = res.find((item) => item.status === DepositStatus.SIGNED);
    });
  }

  public getDepositPrintForms(credit_id: string | number, form_name: string) {
    this.userService
      .getDepositPrintForms(credit_id, form_name)
      .subscribe((blob) => {
        const file = new Blob([blob], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      });
  }

  public formatDate(date: string) {
    let split = date.split("-");
    return `${split[2]}.${split[1]}.${split[0]} г.`;
  }

  ngOnDestroy() {}
}
