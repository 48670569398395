import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MyLocalStorageService } from "../local-storage/local-storage.service";
import { environment } from "src/environments/environment";
import { TUserInfo, ChangePassword, Tranches } from "src/app/models/user";
import { from, Observable } from "rxjs";
import { Deposit } from "src/app/models/deposit";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getUserLoanRequestHistory(fromDate: string, toDate: string) {
    return this.http.get(
      `${environment.apiUrl}/api/user/loan/request/history/?from_date=${fromDate}&to_date=${toDate}`
    );
  }

  public getUserInfo(category = "individual"): Observable<TUserInfo> {
    return this.http.get<TUserInfo>(
      `${environment.apiUrl}/api/user/account/info/?product_category=${category}`
    );
  }
  public getProfileData(): Observable<TUserInfo> {
    return this.http.get<TUserInfo>(
      `${environment.apiUrl}/api/user/account/profile-data/`
    );
  }

  public getPaymentHistory(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/payment/history/`
    );
  }

  getDepositProfile() {
    return this.http.get<any>(`${environment.apiUrl}/api/deposit/profile/`);
  }

  public getRepaymentHistory(
    contract,
    contractDate,
    fromDate,
    toDate,
    category
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/repayment/history/?contract_number=${contract}&contract_date=${contractDate}&from_date=${fromDate}&to_date=${toDate}&category=${category}`
    );
  }

  public registerOrResetCard() {
    // return this.http.
  }

  public changePassword(model: ChangePassword): Observable<TUserInfo> {
    let myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "multipart/form-data");
    let options = { headers: myHeaders };

    return this.http.post<any>(
      `${environment.apiUrl}/api/user/auth/password/change/`,
      model,
      options
    );
  }

  public getActiveRequest(id: number): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/${id}/active-request/`
    );
  }
  public getValidateToSign(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/credits/validate-to-sign/`
    );
  }

  public getLoanCreditsContract(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/credits/contract/`
    );
  }

  public getDepositList(): Observable<Deposit[]> {
    return this.http.get<any>(`${environment.apiUrl}/api/deposit/list/`);
  }

  public uploadFile(
    id: number,
    cardFront: any,
    cardBack: any,
    photo: any
  ): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append("id_card_front", cardFront, cardFront.name);
    formData.append("id_card_back", cardBack, cardBack.name);
    formData.append("photo", photo, photo.name);

    let myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "multipart/form-data");
    let options = { headers: myHeaders };

    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/kyc-upload/`,
      formData,
      options
    );
  }

  public contractSign(
    id: number,
    key: any,
    password: any
  ): Observable<boolean> {
    const formData: FormData = new FormData();
    formData.append("key", key, key.name);
    formData.append("password", password);

    let myHeaders = new HttpHeaders();
    myHeaders.append("Content-Type", "multipart/form-data");
    let options = { headers: myHeaders };

    return this.http.post<any>(
      `${environment.apiUrl}/api/user/loan/contracts/${id}/sign/`,
      formData,
      options
    );
  }

  public getContractsFile(id: number): Observable<boolean> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/contracts/${id}/download/`
    );
  }

  public sendOTP(id: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/send-otp/`,
      null
    );
  }

  public singOTP(id: number, otp_code: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/sign-otp/`,
      { otp_code: otp_code }
    );
  }

  public sendOTPDeposit(): Observable<boolean> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/deposit/validate-to-sign/`
    );
  }

  public singOTPDeposit(otp_code: string): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/deposit/validate-to-sign/`,
      { otp: otp_code }
    );
  }

  public cancelRequest(id: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/cancel/`,
      null
    );
  }

  public signCourier(id: number): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/content/active-request/${id}/sign-by-courier/`,
      null
    );
  }

  public getCardList(contracNumber: string) {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/card/list/?contract_number=${contracNumber}`
    );
  }

  public getDdcapApply() {
    return this.http.get<any>(`${environment.apiUrl}/ddcap/apply/`);
  }

  public deleteCard(id: string) {
    return this.http.delete(
      `${environment.apiUrl}/api/user/card/delete/${id}/`
    );
  }

  public updateCard(card: any) {
    return this.http.put(
      `${environment.apiUrl}/api/user/card/update/${card.id}/`,
      card
    );
  }

  public pay(card: any) {
    return this.http.post(`${environment.apiUrl}/api/user/card/pay/`, card);
  }

  public addCard(data) {
    return this.http.post(`${environment.apiUrl}/api/user/card/create/`, data);
  }

  public getContracts(): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/credits/get-contracts/`
    );
  }

  public getContractsTranches(contractId: number): Observable<TUserInfo> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/contracts/${contractId}/tranches/<int:pk>/ `
    );
  }

  public getTranches(contractId: number): Observable<Tranches> {
    return this.http.get<any>(
      `${environment.apiUrl}/api/user/loan/contracts/${contractId}/tranches/`
    );
  }

  public setTranches(
    contractId: number,
    desiredAmount: number,
    repaymentMethod: "ANNUITY"
  ) {
    return this.http.post(
      `${environment.apiUrl}/api/user/loan/contracts/${contractId}/tranches/`,
      {
        contract_id: contractId,
        desired_amount: desiredAmount,
        repayment_method: repaymentMethod,
      }
    );
  }

  public getPrintForms(
    credit_id: string | number,
    form_name: string
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/content/${credit_id}/print-form/${form_name}.pdf`,
      { responseType: "blob" }
    );
  }

  public getSignedPrintForms(url: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}${url}`, {
      responseType: "blob",
    });
  }

  public getDepositPrintForms(
    credit_id: string | number,
    form_name: string
  ): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/api/deposit/${credit_id}/print-form/${form_name}.pdf`,
      { responseType: "blob" }
    );
  }
}
