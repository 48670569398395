import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { TimeSnackbarShow } from "src/app/config";
import { AuthService } from "src/app/services/auth/auth.service";
import { MyLocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "app-profile-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class ProfileMenuComponent implements OnInit, OnDestroy {
  @Output() changeTab = new EventEmitter<number>();
  @Input() tab: number;

  @ViewChild("content", { static: true }) content!: ElementRef;

  public activeRequest: any = null;
  public activeID: number = null;
  public printApplication: string = "";
  public printCreditContract: string = "";
  public printCreditContractSchedule: string = "";

  setTab(tab: number) {
    this.changeTab.emit(tab);
  }

  constructor(
    private userService: UserService,
    private localStorageService: MyLocalStorageService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (
      this.localStorageService.getUser() &&
      this.localStorageService.getUser().id
    )
      this.getRequestActive();
  }

  private getRequestActive() {
    this.userService
      .getActiveRequest(this.localStorageService.getUser().id)
      .subscribe(
        (res: any) => {
          this.activeRequest = res;
          this.activeID = res.id;

          this.printApplication = res.print_application;
          this.printCreditContract = res.print_credit_contract;
          this.printCreditContractSchedule = res.print_credit_contract_schedule;
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  ngOnDestroy() {}
}
