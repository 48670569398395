import { Component, OnInit, OnDestroy } from "@angular/core";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";

declare let $: any;

@Component({
  selector: "app-profile-prepayment",
  templateUrl: "./prepayment.component.html",
  styleUrls: ["./prepayment.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfilePrepaymentComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
