import { Component, OnInit } from "@angular/core";
import { CalculatorProducts } from "src/app/models/calculator";
import { CalcService } from "src/app/services/calc/calc.service";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
})
export class ProductsComponent implements OnInit {
  constructor(private calcService: CalcService) {}
  private subscribes = new Array<any>();
  public products: Array<CalculatorProducts>;
  public product: CalculatorProducts = {
    name: "",
    period_limits: {
      lower: 0,
      upper: 0,
      bounds: "",
    },
    id: "",
    interest_rate: "",
    principal_limits: {
      bounds: "",
      lower: 0,
      upper: 0,
    },
    margin_coefficient: "",
    margin: "",
  };
  dataOTP = {
    phone: "",
    uuid: "",
  };
  monthly_payment = [];

  ngOnInit() {
    this.getProducts();
  }

  public setProduct(product: CalculatorProducts) {
    this.product = product;
  }

  sendModalOTP({ phone, uuid }) {
    this.dataOTP.phone = phone;
    this.dataOTP.uuid = uuid;
  }

  public getProducts() {
    this.subscribes.push(
      this.calcService.getProducts().subscribe(
        (res) => {
          this.products = res.results;
        },
        (err) => {}
      )
    );
  }

  public formatNumber(number) {
    if (!number) return "";

    const suffixes = [
      "",
      "тыс.",
      "млн.",
      "млрд.",
      "трлн.",
      "квадр.",
      "квинт.",
      "секст.",
      "септ.",
      "окт.",
      "нон.",
      "дец.",
    ];

    let suffixIndex = 0;
    while (number >= 1000 && suffixIndex < suffixes.length - 1) {
      number /= 1000;
      suffixIndex++;
    }

    // Округляем число до двух знаков после запятой
    number = Math.round(number * 100) / 100;

    // Возвращаем число с суффиксом
    return `${number} ${suffixes[suffixIndex]}`;
  }
}
