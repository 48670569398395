import { Component, OnInit, OnDestroy } from "@angular/core";
import { ChangePassword } from "src/app/models/user";
import { UserService } from "src/app/services/user/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/services/auth/auth.service";
import { TimeSnackbarShow } from "src/app/config";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";

@Component({
  selector: "app-profile-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfilePasswordComponent implements OnInit, OnDestroy {
  public checkToValid = false;
  public changePasswordModel = new ChangePassword();

  public requestErrors: any = {};
  public password_is_generated: boolean = false;

  private subscrubes = [];

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit() {}

  public checkFields(changePasswordModel: ChangePassword): Boolean {
    const fields = Object.keys(changePasswordModel);

    for (let i = 0; i < fields.length; i++) {
      if (fields[i] !== "email" && !changePasswordModel[fields[i]]) {
        return false;
      }
    }

    if (
      changePasswordModel.new_password !== changePasswordModel.new_re_password
    ) {
      return false;
    }

    return true;
  }

  public changePassword(model: ChangePassword) {
    this.subscrubes.push(
      this.userService.changePassword(model).subscribe(
        () => {
          this.snackBar.open("Пароль успешно изменен!", null, {
            panelClass: "success-snackbar",
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
          model.new_password = "";
          model.new_re_password = "";
          model.old_password = "";
          this.password_is_generated = false;
          localStorage.removeItem("__password_generated");
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            return this.authService.logout();
          }
          this.requestErrors = err.error;
          let errMsg = "";
          for (let key in err.error) {
            errMsg += err.error[key] + "\n";
          }

          this.snackBar.open(errMsg || "Ошибка при отправке!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });
        }
      )
    );
  }

  ngOnDestroy() {}
}
