import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { TUserInfo } from "src/app/models/user";
import { UserService } from "src/app/services/user/user.service";
import { Subject } from "rxjs";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSnackBar } from "@angular/material";
import { TimeSnackbarShow } from "src/app/config";
import { RepaymentService } from "src/app/services/repayment/repayment.service";
import { takeUntil } from "rxjs/operators";
import { AuthService } from "src/app/services/auth/auth.service";

@Component({
  selector: "app-profile-operations",
  templateUrl: "./operations.component.html",
  styleUrls: ["./operations.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfileOperationsComponent implements OnInit, OnDestroy {
  @Input() userInfo: TUserInfo;

  public requestErrors: any = {};
  public applicationHistory = [];

  public selectedContract: number = 0;
  public productType: string = "";
  public isHidden = false;

  public fromDatePeriod: Date;
  public toDatePeriod: Date;

  public historyPaymentOld = new Array<HistoryPayment>();
  public historyPayment = new Array<HistoryPayment>();

  public amountLoading = false;
  public partialPrepaymentDate: string;
  public amountFullPrepayment: number;
  public amountFullPrepaymentDate: string;
  public amountTodayFullPrepayment: number;
  public isHolidayText: string;
  public isHoliday = false;
  public isKaspi = false;
  public fullPrepayment: boolean = false;
  public partiallyPrepaymentAmount: string;
  public get partialPrepaymentAmountMin() {
    if (!this.userInfo) return 0;
    if (!this.userInfo.user_contract_info) return 0;
    if (!this.userInfo.user_contract_info[this.selectedContract]) return 0;
    if (this.repayment.IP)
      return (
        Math.round(
          (this.userInfo.user_contract_info[this.selectedContract].loan_amount /
            2) *
            100
        ) / 100
      );
    else
      return Math.max(
        50000,
        Math.round(
          this.userInfo.user_contract_info[
            this.selectedContract
          ].payment_schedule
            .filter(
              (_) =>
                Date.parse(_.target_dates) >
                Date.parse(new Date().toDateString())
            )
            .slice(0, 3)
            .map((_) => _.payment)
            .map((_: any) => parseFloat(_))
            .reduce((_, result) => _ + result) * 100
        ) / 100
      );
  }

  public changeType(newType: string) {
    this.selectedContract = this.userInfo.user_contract_info.indexOf(
      this.filteredContracts[0]
    );
    this.changeContract(this.selectedContract);
  }

  public repayment = {
    fromDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
    IP: false,
  };

  public repaymentHistory: [];
  private destroyed$ = new Subject();

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    private repaymentService: RepaymentService,
    private authService: AuthService,
    private numseperator: NumSeparatorPipe
  ) {}

  public password_is_generated: boolean = false;

  public hasContracts(type: string): boolean {
    if (!this.userInfo) return false;
    if (!this.userInfo.user_contract_info) return false;
    if (type == "") return this.filteredContracts.length > 0;

    return (
      this.userInfo.user_contract_info.filter((_) =>
        _.contract_number.startsWith(type)
      ).length > 0
    );
  }

  public get filteredContracts() {
    if (!this.userInfo) return;
    if (!this.userInfo.user_contract_info) return [];
    return this.userInfo.user_contract_info
      .filter((_) => this.repayment.IP != _.contract_number.startsWith("IS"))
      .filter((_) => _.contract_number.startsWith(this.productType));
  }

  ngOnInit() {
    window["contracts"] = () => this.userInfo.user_contract_info;
    window["selectedContract"] = () => this.selectedContract;
  }

  public changeIPTimeout: any = 0;

  public changeIP() {
    this.productType = "";
    this.changeContract(
      this.userInfo.user_contract_info.findIndex((_) =>
        this.filteredContracts.includes(_)
      )
    );
  }

  private getPrepaymentHistory(contractInfo) {
    this.userService
      .getRepaymentHistory(
        contractInfo.contract_number,
        contractInfo.date_of_conclusion,
        this.repayment.fromDate,
        this.repayment.toDate,
        this.repayment.IP ? "entity" : "individual"
      )
      .subscribe((_) => {
        try {
          this.repaymentHistory = _;
          this.repaymentSumm =
            _.length > 0
              ? this.repaymentHistory
                  .map((_: any) => _.payment)
                  .reduce((_: any, result: any) => _ + result)
              : 0;
        } catch {}
      });
  }

  private getLoanRequestHistory() {
    this.userService
      .getUserLoanRequestHistory(this.repayment.fromDate, this.repayment.toDate)
      .subscribe((_: any) => {
        this.applicationHistory = _;
      });
  }

  public changeContract(contractIndex: number) {
    const contract_date =
      this.userInfo.user_contract_info[contractIndex].date_of_conclusion;
    this.isHidden = new Date(contract_date) > new Date();

    this.selectedContract = contractIndex;
    this.getPartialRepaymentAmount(
      this.userInfo.user_contract_info[contractIndex].contract_number,
      false
    );
    this.getFullRepaymentAmount(
      this.userInfo.user_contract_info[contractIndex].contract_number,
      false
    );
    this.getPrepaymentHistory(this.userInfo.user_contract_info[contractIndex]);
    this.checkForMinimalPrepayment("0");
  }

  public repaymentSumm = 0;
  public dates: {
    from: Date;
    to: Date;
  } = {
    from: new Date(Date.now() - 24 * 60 * 60 * 1000 * 30),
    to: new Date(Date.now()),
  };

  private changeDateTimeout: any = 0;

  public changeFromPeriodDate(event: any, force: boolean = false) {
    if (force) event.value = event;
    // console.log(event);
    this.dates.from = event.value;
    this.fromDatePeriod = event.value;
    this.repayment.fromDate = new Date(
      event.value - event.value.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .split("T")[0];
    try {
      this.historyPayment = this.historyPaymentOld.filter((x) => {
        return (
          new Date(x.targetDates) >= this.fromDatePeriod &&
          (this.toDatePeriod
            ? new Date(x.targetDates) <= this.toDatePeriod
            : true)
        );
      });
    } catch (e) {}
    clearTimeout(this.changeDateTimeout);
    this.changeDateTimeout = setTimeout(() => {
      try {
        this.getPrepaymentHistory(
          this.userInfo.user_contract_info[this.selectedContract]
        );
      } catch {}
      try {
        this.getLoanRequestHistory();
      } catch {}
    }, 500);
  }

  public changeToPeriodDate(event: any, force: boolean = false) {
    if (force) event.value = event;
    let date = new Date(event.value.getTime() + 24 * 60 * 60 * 1000);
    this.repayment.toDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .split("T")[0];
    // console.log(this.repayment.toDate);
    this.toDatePeriod = date;
    this.historyPayment = this.historyPaymentOld.filter((x) => {
      return (
        new Date(x.targetDates) <= this.toDatePeriod &&
        (this.fromDatePeriod
          ? new Date(x.targetDates) >= this.fromDatePeriod
          : true)
      );
    });
    clearTimeout(this.changeDateTimeout);
    this.changeDateTimeout = setTimeout(() => {
      try {
        this.getPrepaymentHistory(
          this.userInfo.user_contract_info[this.selectedContract]
        );
      } catch {}
      try {
        this.getLoanRequestHistory();
      } catch {}
    }, 500);
  }

  public getPartialRepaymentAmount(contract_number: string, isKaspi: boolean) {
    this.repaymentService
      .getPartialRepaymentAmount(contract_number, isKaspi)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          this.checkForMinimalPrepayment("0");
          this.partialPrepaymentDate = res.debt_date;
          this.isHoliday = res.is_holiday[0];
          this.isHolidayText = res.is_holiday[1];
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  public getFullRepaymentAmount(contract_number: string, isKaspi: boolean) {
    this.amountLoading = true;
    this.repaymentService
      .getFullRepaymentAmount(contract_number, isKaspi)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          this.amountLoading = false;
          this.amountFullPrepayment = res.current_amount_debt.toFixed(2);
          this.amountFullPrepaymentDate = res.debt_date;
          this.amountTodayFullPrepayment = res.kaspi_debt.toFixed(2);
          this.isHoliday = res.is_holiday[0];
          this.isHolidayText = res.is_holiday[1];
        },
        (err) => {
          this.amountLoading = false;
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  public checkForMinimalPrepayment(sum: string) {
    if (!this.userInfo) return;
    if (!this.userInfo.user_contract_info) return;
    if (!this.userInfo.user_contract_info[this.selectedContract]) return;
    let int_sum = parseFloat(sum.replace(/[^0-9]/g, ""));
    if (this.partialPrepaymentAmountMin > int_sum || isNaN(int_sum)) {
      int_sum = this.partialPrepaymentAmountMin;
    }

    if (
      int_sum >=
      this.userInfo.user_contract_info[this.selectedContract].loan_amount
    ) {
      int_sum =
        this.userInfo.user_contract_info[this.selectedContract].loan_amount;
      this.fullPrepayment = true;
    } else {
      this.fullPrepayment = false;
    }
    this.partiallyPrepaymentAmount = this.numseperator.transform(int_sum);
  }

  ngOnDestroy() {}
}
