import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { TUserInfo } from "src/app/models/user";

declare let $: any;

@Component({
  selector: "app-profile-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfileProfileComponent implements OnInit, OnDestroy {
  @Input() userInfo: TUserInfo;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
