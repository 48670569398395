import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import {
  TUserInfo,
  ChangePassword,
  ContractSign,
  Tranches,
} from "src/app/models/user";
import { UnsubscribeService } from "src/app/services/unsubscribe/unsubscribe.service";
import { UserService } from "src/app/services/user/user.service";
import { MyLocalStorageService } from "src/app/services/local-storage/local-storage.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/services/auth/auth.service";
import { TimeSnackbarShow } from "src/app/config";
import { RepaymentService } from "src/app/services/repayment/repayment.service";
import { NumSeparatorPipe } from "src/app/pipes";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { CreditContract } from "src/app/models/credit";

declare let $: any;

@Component({
  selector: "app-profile-full-prepayment",
  templateUrl: "./full-prepayment.component.html",
  styleUrls: ["./full-prepayment.component.scss"],
  providers: [
    NumSeparatorPipe,
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ProfileFullPrepaymentComponent implements OnInit, OnDestroy {
  @Input() userInfo: TUserInfo;

  public requestErrors: any = {};

  public partiallyPrepaymentAmount: string;
  public get partialPrepaymentAmountMin() {
    if (!this.userInfo) return 0;
    if (!this.userInfo.user_contract_info) return 0;
    if (!this.userInfo.user_contract_info[this.selectedContract]) return 0;
    if (this.repayment.IP)
      return (
        Math.round(
          (this.userInfo.user_contract_info[this.selectedContract].loan_amount /
            2) *
            100
        ) / 100
      );
    else
      return Math.max(
        50000,
        Math.round(
          this.userInfo.user_contract_info[
            this.selectedContract
          ].payment_schedule
            .filter(
              (_) =>
                Date.parse(_.target_dates) >
                Date.parse(new Date().toDateString())
            )
            .slice(0, 3)
            .map((_) => _.payment)
            .map((_: any) => parseFloat(_))
            .reduce((_, result) => _ + result) * 100
        ) / 100
      );
  }

  public fullPrepayment: boolean = false;
  public partialPrepaymentDate: string;
  public amountFullPrepayment: number;
  public amountFullPrepaymentDate: string;
  public amountTodayFullPrepayment: number;
  public isHolidayText: string;
  public isHoliday = false;
  public isKaspi = false;
  public isHidden = false;

  public selectedContract: number = 0;
  public amountLoading = false;
  public arrears = 0;
  public productType: string = "";
  public repaymentHistory: [];
  public repaymentSumm = 0;

  public changeType(newType: string) {
    this.selectedContract = this.userInfo.user_contract_info.indexOf(
      this.filteredContracts[0]
    );
    this.changeContract(this.selectedContract);
  }

  public repayment = {
    fromDate: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30)
      .toISOString()
      .split("T")[0],
    toDate: new Date().toISOString().split("T")[0],
    IP: false,
  };

  private destroyed$ = new Subject();

  constructor(
    private unsubscribeService: UnsubscribeService,
    private userService: UserService,
    private localStorageService: MyLocalStorageService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private repaymentService: RepaymentService,
    private numseperator: NumSeparatorPipe
  ) {}

  public hasContracts(type: string): boolean {
    if (!this.userInfo) return false;
    if (!this.userInfo.user_contract_info) return false;
    if (type == "") return this.filteredContracts.length > 0;

    return (
      this.userInfo.user_contract_info.filter((_) =>
        _.contract_number.startsWith(type)
      ).length > 0
    );
  }

  public get filteredContracts() {
    if (!this.userInfo) return;
    if (!this.userInfo.user_contract_info) return [];
    return this.userInfo.user_contract_info
      .filter((_) => this.repayment.IP != _.contract_number.startsWith("IS"))
      .filter((_) => _.contract_number.startsWith(this.productType));
  }

  ngOnInit() {
    window["selectedContract"] = () => this.selectedContract;
    var d = new Date(new Date().getTime() + 6 * 3600 * 1000);
    this.partialPrepaymentDate =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2);
  }

  private showLoader(text: string) {
    window["showLoader"](text);
  }

  public changeIP(newIP) {
    this.productType = "";
    this.changeContract(
      this.userInfo.user_contract_info.findIndex((_) =>
        this.filteredContracts.includes(_)
      )
    );
  }

  public getPartialRepaymentAmount(contract_number: string, isKaspi: boolean) {
    this.repaymentService
      .getPartialRepaymentAmount(contract_number, isKaspi)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          this.checkForMinimalPrepayment("0");
          this.partialPrepaymentDate = res.debt_date;
          this.isHoliday = res.is_holiday[0];
          this.isHolidayText = res.is_holiday[1];
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  public getFullRepaymentAmount(contract_number: string, isKaspi: boolean) {
    this.amountLoading = true;
    this.repaymentService
      .getFullRepaymentAmount(contract_number, isKaspi)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          this.amountLoading = false;
          this.amountFullPrepayment = res.current_amount_debt.toFixed(2);
          this.amountFullPrepaymentDate = res.debt_date;
          this.amountTodayFullPrepayment = res.kaspi_debt.toFixed(2);
          this.isHoliday = res.is_holiday[0];
          this.isHolidayText = res.is_holiday[1];
        },
        (err) => {
          this.amountLoading = false;
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  public checkBalance(partiallyPrepaymentAmount: string, partial = false) {
    if (
      !this.userInfo ||
      !this.userInfo.account_balance ||
      !partiallyPrepaymentAmount ||
      +this.userInfo.account_balance <
        +partiallyPrepaymentAmount.replace(/\s/g, "")
    ) {
      return false;
    }

    return true;
  }

  public doFullRepaymentAmount(contract_number: string, amount: number) {
    this.showLoader("Идёт оплата, пожалуйста подождите");
    this.repaymentService
      .fullRepaymentAmount(contract_number, amount, this.isKaspi)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: any) => {
          this.snackBar.open(res.message || "Что то пошло не так!", null, {
            duration: TimeSnackbarShow,
            verticalPosition: "top",
          });

          if (!res.status) {
            return;
          }

          window.location.reload();
        },
        (err) => {
          if (err.status === 401) {
            this.snackBar.open(
              "Для начала пожалуйста, войдите в свой аккаунт.",
              null,
              {
                duration: TimeSnackbarShow,
                verticalPosition: "top",
              }
            );
            this.authService.logout();
          }
        }
      );
  }

  public changeContract(contractIndex: number) {
    const contract_date =
      this.userInfo.user_contract_info[contractIndex].date_of_conclusion;
    this.isHidden = new Date(contract_date) > new Date();

    this.selectedContract = contractIndex;
    this.getPartialRepaymentAmount(
      this.userInfo.user_contract_info[contractIndex].contract_number,
      false
    );
    this.getFullRepaymentAmount(
      this.userInfo.user_contract_info[contractIndex].contract_number,
      false
    );
    this.getPrepaymentHistory(this.userInfo.user_contract_info[contractIndex]);
    this.checkForMinimalPrepayment("0");
  }

  public checkForMinimalPrepayment(sum: string) {
    if (!this.userInfo) return;
    if (!this.userInfo.user_contract_info) return;
    if (!this.userInfo.user_contract_info[this.selectedContract]) return;
    let int_sum = parseFloat(sum.replace(/[^0-9]/g, ""));
    if (this.partialPrepaymentAmountMin > int_sum || isNaN(int_sum)) {
      int_sum = this.partialPrepaymentAmountMin;
    }

    if (
      int_sum >=
      this.userInfo.user_contract_info[this.selectedContract].loan_amount
    ) {
      int_sum =
        this.userInfo.user_contract_info[this.selectedContract].loan_amount;
      this.fullPrepayment = true;
    } else {
      this.fullPrepayment = false;
    }
    this.partiallyPrepaymentAmount = this.numseperator.transform(int_sum);
  }

  private getPrepaymentHistory(contractInfo) {
    this.userService
      .getRepaymentHistory(
        contractInfo.contract_number,
        contractInfo.date_of_conclusion,
        this.repayment.fromDate,
        this.repayment.toDate,
        this.repayment.IP ? "entity" : "individual"
      )
      .subscribe((_) => {
        try {
          this.repaymentHistory = _;
          this.repaymentSumm =
            _.length > 0
              ? this.repaymentHistory
                  .map((_: any) => _.payment)
                  .reduce((_: any, result: any) => _ + result)
              : 0;
        } catch {}
      });
  }

  public getPrepaymentErrorText() {
    if (this.arrears > 0) {
      return "Вам необходимо погасить просроченную задолженность";
    }
    if (this.isHoliday) {
      return this.isHolidayText;
    }
    return "";
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }
}
