export class User {
  id: number;
  phone: string;
  email: string;
  fio: string;
}

export type TUserInfo = {
  status: string;
  account_balance: number;
  client_first_name: string;
  client_last_name: string;
  client_name: string;
  client_patronymic: string;
  email: string;
  iin: number;
  mobile_phone: string;
  other_information: any;
  user_contract_info: Array<TUserContractInfo>;

  first_name: string;
  last_name: string;
  middle_name: string;
  person: Person;
  additional_contacts: Array<TAdditionalContacts>;
};

export type TAdditionalContacts = {
  first_name: string;
  mobile_phone: string;
};

export type Person = {
  age: number;
  birthday: string;
  gender: string;
  iin: string;
};

export type TUserContractInfo = {
  arrears: number;
  contract_number: string; // "IS0101486";
  date_of_conclusion: string; // "2020-01-30";
  expiration_date: string; //"2022-08-01";
  kdif: number;
  loan_amount: number;
  planned_payment_amount: number;
  planned_payment_date: string; //"2020-03-02";
  remnant_of_principal: number;
  payment_schedule: Array<TPaymentShedule>;
  rate_remuneration: number;
  prints: string;
  credit_id: number;
  id: number;
};

export type TPaymentShedule = {
  amount_of_repayment: number; //"23049.93"
  payment: number; //"42700"
  payment_number: number;
  payment_of_remuneration: number; //"19650.07"
  planned_balance: number; //"668448.07"
  target_dates: string; //"2020-03-02"
};

export class ChangePassword {
  old_password: string;
  new_password: string;
  new_re_password: string;
}

export class ContractSign {
  key: File;
  password: string;
}

export interface Payment {
  month: number;
  maturity_date: string;
  principal_amount: number;
  reward_amount: number;
  monthly_payment: number;
  remaining_debt: number;
  trade_margin: number;
  total_amount_due: number;
  remaining_debt_with_overpayment: number;
}

export interface PaymentSchedule {
  principal: number;
  period: number;
  interest_rate: number;
  payment_date: string;
  contract_date: string;
  payments: Payment[];
}

export interface MainContractParams {
  principal: string;
  interest_rate: string;
  period: number;
  repayment_method: string;
  desired_repayment_day: string | null;
  contract_date: string;
  payment_schedule: PaymentSchedule;
  last_payment_date: string;
}

export interface MainContract {
  id: number;
  product: string;
  borrower: number;
  params: MainContractParams;
  contract_number: string;
  contract_date: string;
  contract_status: string;
  reward: string;
  payments: any[]; // Типизация не представлена в данных, нужно уточнить типы
  remaining_principal: string;
  overdue_amount: string;
  penalty_amount: string;
  signed_at: string;
  closed_at: string | null;
  credit_id: number;
  document_signed: string;
}

export interface Borrower {
  id: number;
  genderDisplay: string;
  iin: string;
  gender: string;
  birthday: string;
}

export interface Tranche {
  id: number;
  credit: number;
  contract: string | null;
  main_contract: MainContract;
  credit_params: number;
  status: string | null;
  approved_date: string | null;
  completed_date: string | null;
  canceled_date: string | null;
  payments: any[] | null; // Типизация не представлена в данных, нужно уточнить типы
  borrower: Borrower;
}

export type Tranches = {
  status?: string | null;
  available_amount?: number | null;
  tranches?: Tranche[];
};
